import React, { Component, FC } from 'react';
import { repeat } from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createBemHelper } from '../BEM';
import ReactTooltip from 'react-tooltip';
import './Grid.scss';
import '../../../src/utils/fontawesome';

const BEM = createBemHelper('union-find-grid');

export interface GridProps {
  preset: PresetType;
  subtext: string;
}

type PresetType = 'intro';
type Coordinate = { r: number; c: number };

export class Grid extends Component<GridProps> {
  render() {
    const { rows, columns } = this.config;

    return (
      <div className={BEM()}>
        <div
          className={BEM('grid')}
          style={{
            gridTemplateRows: repeat('50px', rows).join(' '),
            gridTemplateColumns: repeat('50px', columns).join(' '),
          }}
        >
          {this.buildBlocks()}
          <ReactTooltip />
        </div>
        <div className={BEM('subtext')}>{this.props.subtext}</div>
      </div>
    );
  }

  private buildBlocks() {
    const { rows, columns, cities, dots } = this.config;

    const result = [];
    for (let r = 0; r < rows; r++) {
      for (let c = 0; c < columns; c++) {
        let has = undefined;
        if (cities.find(city => city.c === c && city.r === r)) {
          has = 'city';
        }
        const dot = dots.find(dot => dot.c === c && dot.r === r);
        if (dot) {
          has = `dot-${dot.player === 'alice' ? 'a' : 'b'}`;
        }
        result.push(this.block(r, c, has));
      }
    }
    return result;
  }

  private block(r: number, c: number, has?: 'city' | 'dot-a' | 'dot-b') {
    const dataTip = [
      has === 'city' ? 'City' : undefined,
      has === 'dot-a' ? 'Alice' : undefined,
      has === 'dot-b' ? 'Bob' : undefined,
      `(${r}, ${c})`,
    ]
      .filter(Boolean)
      .join('<br />');
    return (
      <div className={BEM('block')} data-tip={dataTip} data-multiline={'true'}>
        {has === 'city' && <FontAwesomeIcon color={'#383961'} icon={'home'} />}
        {has === 'dot-a' && <Dot color="green" />}
        {has === 'dot-b' && <Dot color="red" />}
      </div>
    );
  }

  private get config(): {
    rows: number;
    columns: number;
    cities: Coordinate[];
    dots: (Coordinate & { player: 'bob' | 'alice' })[];
  } {
    const { preset } = this.props;

    if (preset === 'intro') {
      return {
        rows: 6,
        columns: 9,
        cities: [
          { r: 1, c: 4 },
          { r: 3, c: 1 },
          { r: 4, c: 7 },
        ],
        dots: [
          { r: 1, c: 3, player: 'alice' },
          { r: 1, c: 2, player: 'bob' },
          { r: 1, c: 1, player: 'alice' },
          { r: 2, c: 1, player: 'bob' },

          { r: 2, c: 4, player: 'bob' },
          { r: 3, c: 4, player: 'alice' },
          { r: 4, c: 4, player: 'alice' },
          { r: 4, c: 5, player: 'bob' },
          { r: 4, c: 6, player: 'bob' },
        ],
      };
    }

    throw new Error(`Unknown preset`);
  }
}

export const Dot: FC<{ color: string }> = props => {
  const hex = props.color === 'red' ? '#EF233C' : '#8AC926';
  return <FontAwesomeIcon color={hex} icon={'circle'} />;
};
