import React, { FC } from 'react';
import { createBemHelper } from '../BEM';
import './Set.scss';

const BEM = createBemHelper('union-find-set');

export type Set = {
  parent: string;
  elements: string[];
};
export type Preset = 'intro';
export interface SetProps {
  preset: Preset;
}

export const Set: FC<SetProps> = props => {
  const { preset } = props;
  return <div className={BEM()}>{presetToSets(preset).map(SingleSet)}</div>;
};

export const SingleSet = (set: Set) => {
  const { parent, elements } = set;
  return (
    <div key={`label-${parent}`} className={BEM('set')}>
      <div className={BEM('set-elements')}>{elements.join(' ')}</div>
      <div className={BEM('set-label')}>{parent}</div>
    </div>
  );
};

const presetToSets = (preset: Preset): Set[] => {
  if (preset === 'intro') {
    return ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'].map(value => {
      return {
        parent: value,
        elements: [value],
      };
    });
  }
};
