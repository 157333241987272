import BEMHelper from 'react-bem-helper';

export const createBemHelper = (
  componentName: string,
  prefix: string = 'ck'
) => {
  return BEMHelper({
    name: componentName,
    prefix: `${prefix}-`,
    outputIsString: true,
  });
};
