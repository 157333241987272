import { TestCustom } from './TestCustom';
import { Grid, Dot } from './UnionFind/Grid';
import { Set } from './UnionFind/Set';

export const CustomComponents = () => {
  return {
    'test-custom': TestCustom,
    'union-find-grid': Grid,
    'union-find-dot': Dot,
    'union-find-set': Set,
  };
};
